import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthState } from './module/auth/state/auth.state';
import { CompanySettingService } from './module/setting/service/company-setting.service';
import { ConfirmComponent } from './shared/components/confirm/confirm.component';
import { DialogService } from './shared/services/dialog.service';

import { PROFET_APPS } from '@propmix/profet-common-header';
import { ProfetEulaService } from '@propmix/profet-eula';
import { tap } from 'rxjs';
import { SnackBarService } from './shared/services/snack-bar.service';
import { CompanyState } from './shared/state/company.state';
import { UserState } from './shared/state/user.state';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  selectedCompany = new FormControl(this.companyState.companySetting.companyKey);
  previousSelectedCompany: string | null = this.companyState.companySetting.companyKey;
  app = PROFET_APPS.appraisal_valuation;

  constructor(
    public authState: AuthState,
    // private slidingSheetService: SlidingSheetService,
    public userState: UserState,
    public companyState: CompanyState,
    private dialogService: DialogService,
    private router: Router,
    private companySettingService: CompanySettingService,
    private snackBarService: SnackBarService,
    private profetEulaService: ProfetEulaService
    // private _commonHeaderSer: CommonHeaderService
  ) {}

  ngOnInit() {
    if (this.authState.isAuthenticated) this.profetEulaService.verify();
    // this._commonHeaderSer.activeMenuList$.subscribe((res) => {
    //   console.log(res); // menu list
    // });
    // this._commonHeaderSer.companyList$.subscribe((res) => {
    //   console.log(res); // company list
    // });
    this.selectedCompany.valueChanges.pipe(tap((value) => this._switchCompany(value as string))).subscribe();
  }

  // sideMenuToggle() {
  //   this.slidingSheetService.loadComponent(MainNavigationComponent).show();
  // }

  private _switchCompany(companyKey: string): void {
    const selectedCompany = this.companyState.companyList?.find((company) => company.key === companyKey);

    const config = {
      buttonLabel: ['Cancel', 'Yes, Switch Company'],
      description: `Do you wish to switch to ${selectedCompany?.displayValue}?`,
      title: `Switch to ${selectedCompany?.displayValue}`,
    };
    const dialogRef = this.dialogService.openDialog(ConfirmComponent, {
      width: '500px',
      data: config,
    });
    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res && this.selectedCompany.value) {
        this.companySettingService.switchCompany(this.selectedCompany.value).subscribe((res) => {
          if (res) {
            this.previousSelectedCompany = this.selectedCompany.value;
            this.router.navigate(['/orders'], { replaceUrl: true });
          } else {
            this.snackBarService.open('error', 'Failed to switch company. Please try again later.');
            this.selectedCompany.patchValue(this.previousSelectedCompany, { emitEvent: false });
          }
        });
      } else {
        this.selectedCompany.patchValue(this.previousSelectedCompany, { emitEvent: false });
      }
    });
  }
}
