import { Component, ElementRef, EventEmitter, Output, Renderer2, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { AddressConfig } from 'src/app/shared/config/address.config';
@Component({
  standalone: true,
  selector: 'app-google-autocomplete',
  templateUrl: './google-autocomplete.component.html',
  styleUrls: ['./google-autocomplete.component.scss'],
  imports: [MatFormFieldModule, MatIconModule, MatInputModule, ReactiveFormsModule],
})
export class GoogleAutocompleteComponent {
  autoCompleteInput: any;
  addressFormControl = new FormControl();
  firstPrediction: google.maps.places.AutocompletePrediction | null = null;

  @Output() addressSelected: EventEmitter<any> = new EventEmitter();

  @ViewChild('addressField') addressField!: ElementRef;

  constructor(private _renderer: Renderer2) {}

  ngAfterViewInit() {
    const autocomplete = new google.maps.places.Autocomplete(this.addressField.nativeElement, {
      componentRestrictions: { country: 'US' },
      types: ['geocode', 'establishment'],
    });

    this._renderer.setProperty(this.addressField.nativeElement, 'placeholder', '');

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this.invokeEvent(place);
    });

    const service = new google.maps.places.AutocompleteService();
    this.addressField.nativeElement.addEventListener('input', () => {
      service.getPlacePredictions({ input: this.addressField.nativeElement.value }, (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && predictions?.length) {
          this.firstPrediction = predictions[0]; // Store the first prediction
        } else {
          this.firstPrediction = null;
        }
      });
    });
  }

  async invokeEvent(place?: google.maps.places.PlaceResult) {
    if (place && place.address_components) {
      this.autoCompleteInput = place.formatted_address;
      this._emitAddressComponents(place);
    } else {
      const geoCodePlace = await this.getPlaceFromGeocoder(this.firstPrediction?.description);
      this.addressFormControl.patchValue(this.firstPrediction?.description);
      this._emitAddressComponents(geoCodePlace);
    }
  }

  async getPlaceFromGeocoder(address?: string): Promise<Partial<google.maps.places.PlaceResult>> {
    return new Promise((resolve) => {
      new google.maps.Geocoder().geocode({ address: address }, (results: google.maps.GeocoderResult[] | null) => {
        const geoCodeResult = {
          address_components: results?.[0].address_components,
          formatted_address: results?.[0].formatted_address,
          geometry: results?.[0].geometry,
        };
        return resolve(geoCodeResult);
      });
    });
  }

  resetAutocomplete() {
    this.addressFormControl?.setValue('', { emitEvent: false });
  }

  private _emitAddressComponents(place: google.maps.places.PlaceResult): void {
    const addressComponent: any = {};

    place?.address_components?.forEach((element: any) => {
      if (element.types.includes('street_number')) {
        addressComponent.streetNumber = element.long_name || '';
      } else if (element.types.includes('subpremise')) {
        const subPremise: string = element.short_name;
        const prefix = AddressConfig.unitNumberPrefixList.find((prefix) => subPremise.includes(prefix));
        if (prefix) subPremise.replace(prefix, '');
        addressComponent.unitNumber = subPremise;
      } else if (element.types.includes('route')) {
        addressComponent.streetName = element.long_name || '';
      } else if (element.types.includes('sublocality_level_1')) {
        addressComponent.city = element.long_name;
      } else if (element.types.includes('locality')) {
        addressComponent.city = element.long_name;
      } else if (element.types.includes('administrative_area_level_1')) {
        addressComponent.state = element.short_name;
      } else if (element.types.includes('administrative_area_level_2')) {
        addressComponent.county = element.long_name || '';
      } else if (element.types.includes('postal_code')) {
        addressComponent.zip = element.long_name || '';
      }
    });

    addressComponent.fullAddress = place.formatted_address ?? '';
    addressComponent.streetAddress = addressComponent.fullAddress?.split(',')[0] ?? '';
    addressComponent.latitude = place?.geometry?.location?.lat() ?? '';
    addressComponent.longitude = place?.geometry?.location?.lng() ?? '';

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    this.addressSelected.emit(addressComponent);
  }
}
