import { PropertyDataSourceType } from 'src/app/module/order/interface/order.interface';
import { PropertyFieldConfigMap } from 'src/app/module/property/interface/property.interface';

export class PropertyConfig {
  /**
   * All list of sources for property data.
   */
  static readonly propertyDataSourceList: PropertyDataSourceType[] = [
    'userRecord',
    'inspectionRecord',
    'listingRecord',
    'publicRecord',
  ];

  /**
   * Property field configuration.
   * This will store all config like datatype, prefix, suffix, etc. for any property field.
   * Currently this is being maintained as a static object in the FE, but in future this can be fetched from the BE while loading an order.
   */
  static readonly propertyFieldConfig: PropertyFieldConfigMap = {
    AboveGradeFinishedArea: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Above Grade Finished Area',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: 'sqft',
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    AboveGradeFinishedAreaSource: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Above Grade Finished Area Source',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    AboveGradeFinishedAreaUnits: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Above Grade Finished Area Units',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['AboveGradeFinishedArea'],
    },
    adjustedPerSquareFeet: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Adjusted/sqft',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    adjustedPrice: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Adjusted Price',
      isMultiSelect: false,
      maxSelection: null,
      prefix: '$',
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    adjustedPricePerSquareFeet: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Adjusted Price/sqft',
      isMultiSelect: false,
      maxSelection: null,
      prefix: '$',
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    adjustmentValue: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Adjustment Value',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    AgentAddress: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Agent Address',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    AgentRemarks: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Agent Remarks',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    appeal: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'appeal',
      fieldDisplayName: 'Appeal',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    Appliances: {
      acceptCustomValue: false,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'appliances',
      fieldDisplayName: 'Appliances',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    ArchitecturalStyle: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Architectural Style',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    AssessedImprovementValue: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Assessed Improvement Value',
      isMultiSelect: false,
      maxSelection: null,
      prefix: '$',
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    AssessedLandValue: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Assessed Land Value',
      isMultiSelect: false,
      maxSelection: null,
      prefix: '$',
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    AssessedValue: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Assessed Value',
      isMultiSelect: false,
      maxSelection: null,
      prefix: '$',
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    AssessedYear: {
      acceptCustomValue: true,
      datatype: 'numberString',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Assessed Year',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    AssessorsMapReference: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Assessors Map Reference',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    AssociationFee: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Association Fee',
      isMultiSelect: false,
      maxSelection: null,
      prefix: '$',
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    AssociationFeeFrequency: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'associationFeeFrequency',
      fieldDisplayName: 'Association Fee Frequency',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    AssociationFeeIncludes: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Association Fee Includes',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    AssociationName: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Association Name',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    AssociationYN: {
      acceptCustomValue: false,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'YNType',
      fieldDisplayName: 'Association YN',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    AttachedGarage: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Attached Garage',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: 'ga',
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    AttachedGarageYN: {
      acceptCustomValue: false,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'YNType',
      fieldDisplayName: 'Attached Garage YN',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    AttachmentType: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'attachmentType',
      fieldDisplayName: 'Attachment Type',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    AverageDailyRent: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Average Daily Rent',
      isMultiSelect: false,
      maxSelection: null,
      prefix: '$',
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    Basement: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Basement',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    BasementBathroomsFull: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Basement Bathrooms Full',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: 'brf',
      ignoreAutoAdjustment: false,
      referenceFieldList: ['Basement'],
    },
    BasementBathroomsHalf: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Basement Bathrooms Half',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: 'brh',
      ignoreAutoAdjustment: false,
      referenceFieldList: ['Basement'],
    },
    BasementBedrooms: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Basement Bedrooms',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: 'bed',
      ignoreAutoAdjustment: false,
      referenceFieldList: ['Basement'],
    },
    BasementEntry: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Basement Entry',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['Basement'],
    },
    BasementOther: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Basement Other',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: 'o',
      ignoreAutoAdjustment: false,
      referenceFieldList: ['Basement'],
    },
    BasementRecRooms: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Basement Rec Rooms',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: 'rec',
      ignoreAutoAdjustment: false,
      referenceFieldList: ['Basement'],
    },
    BasementType: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'basementType',
      fieldDisplayName: 'Basement Type',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['Basement', 'Foundation', 'BelowGradeFinishedArea'],
    },
    BathroomsDecimal: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Bathrooms Decimal',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 2,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    BathroomsFull: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Bathrooms Full',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    BathroomsFullCalculated: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Bathrooms Full',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    BathroomsHalf: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Bathrooms Half',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    BathroomsHalfCalculated: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Bathrooms Half',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    bathroomsTotal: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Bathrooms Total',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    BathroomsTotalInteger: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Total Bathrooms',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    BedroomsTotal: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Bedrooms',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    BelowGradeFinishedArea: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Below Grade Finished Area',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: 'sqft',
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    BelowGradeFinishedAreaSource: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Below Grade Finished Area Source',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    BelowGradeFinishedAreaUnits: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Below Grade Finished Area Units',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['BelowGradeFinishedArea'],
    },
    BelowGradeTotalArea: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Below Grade Total Area',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: 'sqft',
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['Basement'],
    },
    BorrowerName: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Borrower Name',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    BuildingAreaTotal: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Building Area',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: 'sqft',
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    BuildingCondition: {
      acceptCustomValue: false,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'ConditionType',
      fieldDisplayName: 'Building Condition',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    BuiltInGarage: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Built-In Garage',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: 'gbi',
      ignoreAutoAdjustment: false,
      referenceFieldList: ['ParkingFeatures', 'OtherParking'],
    },
    BuyerAgentEmail: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Buyer Agent Email',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    BuyerAgentFullName: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Buyer Agent Full Name',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    BuyerAgentOfficePhone: {
      acceptCustomValue: true,
      datatype: 'numberString',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Buyer Agent Office Phone',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    BuyerFinancing: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'buyerFinancing',
      fieldDisplayName: 'Buyer Financing',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    BuyerOfficeEmail: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Buyer Office Email',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    BuyerOfficeName: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Buyer Office Name',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    BuyerOfficePhone: {
      acceptCustomValue: true,
      datatype: 'numberString',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Buyer Office Phone',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    Carport: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Carport',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: 'cp',
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    CarportSpaces: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Carport Spaces',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['ParkingFeatures', 'OtherParking'],
    },
    CarportYN: {
      acceptCustomValue: false,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'YNType',
      fieldDisplayName: 'Carport Y/N',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['ParkingFeatures', 'OtherParking'],
    },
    CarStorageType: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'garageType',
      fieldDisplayName: 'Car Storage Type',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['ParkingFeatures', 'OtherParking'],
    },
    CensusTractId: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Census Tract Id',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    City: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'City',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    CleaningFee: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Cleaning Fee',
      isMultiSelect: false,
      maxSelection: null,
      prefix: '$',
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    CloseDate: {
      acceptCustomValue: true,
      datatype: 'date',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Sold Date',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: true,
      referenceFieldList: [],
    },
    ClosePrice: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Sold Price',
      isMultiSelect: false,
      maxSelection: null,
      prefix: '$',
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    comments: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Comments',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    Concessions: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Concessions',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    ConcessionsAmount: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Concessions Amount',
      isMultiSelect: false,
      maxSelection: null,
      prefix: '$',
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    ConcessionsComments: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Concessions Comments',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    ConstQuality: {
      acceptCustomValue: false,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'qualityOfConstruction',
      fieldDisplayName: 'Construction Quality',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    ConstructionType: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Construction Type',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    ContractDate: {
      acceptCustomValue: true,
      datatype: 'date',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Contract Date',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    Cooling: {
      acceptCustomValue: false,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'cooling',
      fieldDisplayName: 'Cooling',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    CoolingType: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'cooling',
      fieldDisplayName: 'Cooling Type',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['Cooling'],
    },
    CountyOrParish: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'County/Parish',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    Covered: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Covered',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    CoveredSpaces: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Covered Spaces',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    coverImageUrl: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Cover Image',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    DataSource: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Data Source',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    DaysAvailableLast12Months: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Days Available Last 12 Months',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: 'days',
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    DaysBlockedLast12Months: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Days Blocked Last 12 Months',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: 'days',
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    DaysOnMarket: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Days on Market',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: 'days',
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    DaysReservedLast12Months: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Days Reserved Last 12 Months',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: 'days',
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    derived_adjustedMonthlyRent: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Adjusted Monthly Rent',
      isMultiSelect: false,
      maxSelection: null,
      prefix: '$',
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    derivedAttachmentType: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'attachmentType',
      fieldDisplayName: 'Attachment Type',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['AttachmentType'],
    },
    derivedHeating: {
      acceptCustomValue: false,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'heating',
      fieldDisplayName: 'Heating',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['Heating', 'HeatingFuelType', 'HeatingType'],
    },
    derivedLotFeatures: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'lotFeatures',
      fieldDisplayName: 'Location',
      isMultiSelect: true,
      maxSelection: 2,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['LotFeatures'],
    },
    derivedView: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'viewFactor',
      fieldDisplayName: 'View',
      isMultiSelect: true,
      maxSelection: 2,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['View'],
    },
    derivedBuildingCondition: {
      acceptCustomValue: false,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'ConditionType',
      fieldDisplayName: 'Building Condition',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['BuildingCondition'],
    },
    derivedConstQuality: {
      acceptCustomValue: false,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'qualityOfConstruction',
      fieldDisplayName: 'Construction Quality',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['ConstQuality'],
    },
    derivedAssociationFeeFrequency: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'associationFeeFrequency',
      fieldDisplayName: 'Association Fee Frequency',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    DetachedGarage: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Detached Garage',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: 'gd',
      ignoreAutoAdjustment: false,
      referenceFieldList: ['ParkingFeatures', 'OtherParking'],
    },
    Distance: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Distance',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: 'miles',
      maxFractionDigits: 1,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    DistressedYN: {
      acceptCustomValue: false,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'YNType',
      fieldDisplayName: 'Distressed YN',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    DistressType: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Distress Type',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    Driveway: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Driveway',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: 'dw',
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    ElementarySchool: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Elementary School',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    ElementarySchoolDistrict: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Elementary School District',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    EntryLevel: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Entry Level',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    EntryLocation: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Entry Location',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    ExteriorFeatures: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Exterior Features',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    FIPS: {
      acceptCustomValue: true,
      datatype: 'numberString',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'FIPS',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    FireplaceFeatures: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Fireplace Features',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    FireplacesTotal: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Fireplaces Total',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['FireplaceFeatures'],
    },
    FireplaceYN: {
      acceptCustomValue: false,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'YNType',
      fieldDisplayName: 'Fireplace YN',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    Flooring: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Flooring',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    Foundation: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Foundation',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['FoundationDetails'],
    },
    FoundationDetails: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Foundation Details',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['Foundation'],
    },
    FoundationWalls: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Foundation Walls',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    FunctionalUtility: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Functional Utility',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    Furnished: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Furnished',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    GarageArea: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Garage Area',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: 'sqft',
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['ParkingFeatures', 'OtherParking'],
    },
    GarageNumber: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Garage Number',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: 'sqft',
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['ParkingFeatures', 'OtherParking'],
    },
    GarageSpaces: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Garage Spaces',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['ParkingFeatures', 'OtherParking'],
    },
    GarageYN: {
      acceptCustomValue: false,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'YNType',
      fieldDisplayName: 'Garage YN',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['ParkingFeatures', 'OtherParking'],
    },
    GreenEnergyEfficient: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Green Energy Efficient',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    GreenEnergyGeneration: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Green Energy Generation',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    Heating: {
      acceptCustomValue: false,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'heating',
      fieldDisplayName: 'Heating',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['Heating'],
    },
    HeatingFuelType: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Heating Fuel Type',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['Heating'],
    },
    HeatingType: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Heating Type',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['Heating'],
    },
    HighSchool: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'High School',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    HighSchoolDistrict: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'High School District',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    hoaDues: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'HOA Dues',
      isMultiSelect: false,
      maxSelection: null,
      prefix: '$',
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    HostFee: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Host Fee',
      isMultiSelect: false,
      maxSelection: null,
      prefix: '$',
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    ImageURLs: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Image URLs',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    InspectionDate: {
      acceptCustomValue: true,
      datatype: 'date',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Inspection Date',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    InspectionType: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'inspectionType',
      fieldDisplayName: 'Inspection Type',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    InspectorName: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Inspector Name',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    InspectorPhone: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Inspector Phone',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    InspectorEmail: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Inspector Email',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    InteriorFeatures: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Interior Features',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    isVandalized: {
      acceptCustomValue: false,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'YNType',
      fieldDisplayName: 'Has been Vandalized',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    LastSaleContractDate: {
      acceptCustomValue: true,
      datatype: 'date',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Last Sale Date',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    LastSaleDate: {
      acceptCustomValue: true,
      datatype: 'date',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Last Sale Date',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    LastSaleDocumentType: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Last Sale Document Type',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    LastSalePrice: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Last Sale Price',
      isMultiSelect: false,
      maxSelection: null,
      prefix: '$',
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    LastSaleRecordingDate: {
      acceptCustomValue: true,
      datatype: 'date',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Last Sale Recording Date',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    Latitude: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 7,
      enumKey: null,
      fieldDisplayName: 'Latitude',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 6,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    LegalSubdivisionName: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Legal Subdivision Name',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    Levels: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Levels',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    ListAgentEmail: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'List Agent Email',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    ListAgentFullName: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'List Agent Full Name',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    ListAgentPreferredPhone: {
      acceptCustomValue: true,
      datatype: 'numberString',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'List Agent Preferred Phone',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    ListedRent: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Listed Rent',
      isMultiSelect: false,
      maxSelection: null,
      prefix: '$',
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    ListingContractDate: {
      acceptCustomValue: true,
      datatype: 'date',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Listing Contract Date',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    ListingDescription: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Listing Description',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    ListingId: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Listing Id',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    ListingType: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Listing Type',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    ListOfficeEmail: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'List Office Email',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    ListOfficeName: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'List Office Name',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    ListOfficePhone: {
      acceptCustomValue: true,
      datatype: 'numberString',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'List Office Phone',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    ListPrice: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'List Price',
      isMultiSelect: false,
      maxSelection: null,
      prefix: '$',
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    ListPriceMonthly: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'List Price Monthly',
      isMultiSelect: false,
      maxSelection: null,
      prefix: '$',
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    ListPriceNightly: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Average Daily Rent',
      isMultiSelect: false,
      maxSelection: null,
      prefix: '$',
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    ListPriceWeekly: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'List Price Weekly',
      isMultiSelect: false,
      maxSelection: null,
      prefix: '$',
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    LivingArea: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Living Area',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: 'sqft',
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    LivingAreaSource: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Living Area Source',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    LivingAreaUnits: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Living Area Units',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    LocationFactors: {
      acceptCustomValue: false,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'lotFeatures',
      fieldDisplayName: 'Location Factors',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['LotFeatures', 'WaterFrontFeatures'],
    },
    LocationRating: {
      acceptCustomValue: false,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'locationRating',
      fieldDisplayName: 'Location Rating',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    Longitude: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 7,
      enumKey: null,
      fieldDisplayName: 'Longitude',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 6,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    LotFeatures: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'lotFeatures',
      fieldDisplayName: 'Lot Features',
      isMultiSelect: false,
      maxSelection: 2,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    LotSizeAcres: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Lot Size Acres',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: 'acres',
      maxFractionDigits: 2,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    LotSizeDimensions: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Lot Size Dimensions',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    LotSizeSquareFeet: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Lot Size Square Feet',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: 'sqft',
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    MainLevelBathrooms: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Main Level Bathrooms',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    MainLevelBedrooms: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Main Level Bedrooms',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    MarketImprovementValue: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Market Improvement Value',
      isMultiSelect: false,
      maxSelection: null,
      prefix: '$',
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    MarketLandValue: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Market Land Value',
      isMultiSelect: false,
      maxSelection: null,
      prefix: '$',
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    MarketValue: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Market Value',
      isMultiSelect: false,
      maxSelection: null,
      prefix: '$',
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    MiddleOrJuniorSchool: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Middle Or Junior School',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    MiddleOrJuniorSchoolDistrict: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Middle Or Junior School District',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    MlsBoard: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'MLS Board',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    MLSListingNumber: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'MLS Listing Number',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    MlsStatus: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'MLS Status',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    ModificationTimestamp: {
      acceptCustomValue: true,
      datatype: 'date',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Modification Timestamp',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    NeighborhoodLocation: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'locationType',
      fieldDisplayName: 'Neighborhood Location',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    OccupancyRate: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Occupancy Rate',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: '%',
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    OfficeAddress1: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Office Address 1',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    OffMarketTimestamp: {
      acceptCustomValue: true,
      datatype: 'date',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Off Market Timestamp',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    OpenParking: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Open Parking',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['ParkingFeatures', 'OtherParking'],
    },
    OpenParkingSpaces: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Open Parking Spaces',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['ParkingFeatures', 'OtherParking'],
    },
    OpenParkingYN: {
      acceptCustomValue: false,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'YNType',
      fieldDisplayName: 'Open Parking Y/N',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['ParkingFeatures', 'OtherParking'],
    },
    OriginalEntryTimestamp: {
      acceptCustomValue: true,
      datatype: 'date',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Original Entry Timestamp',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    OriginalListPrice: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Original List Price',
      isMultiSelect: false,
      maxSelection: null,
      prefix: '$',
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    OriginatingSystemName: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Originating System Name',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    OtherParking: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Other Parking',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['ParkingFeatures', 'OtherParking'],
    },
    Owner1FullName: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Owner 1 Full Name',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    Owner2FullName: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Owner 2 Full Name',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    OwnerOccupied: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'YNType',
      fieldDisplayName: 'Owner Occupied',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    ParcelNumber: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Parcel Number',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    ParkingFeatures: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Parking Features',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['OtherParking'],
    },
    ParkingTotal: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Parking Total',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [
        'ParkingFeatures',
        'OtherParking',
        'GarageSpaces',
        'CarportSpaces',
        'OpenParkingSpaces',
        'CoveredSpaces',
      ],
    },
    PatioAndPorchFeatures: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Patio And Porch Features',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    PatioType: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Patio Type',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['PatioAndPorchFeatures'],
    },
    PendingTimestamp: {
      acceptCustomValue: true,
      datatype: 'date',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Pending Timestamp',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    PeopleCapacity: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'People Capacity',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    PMXPropertyId: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'PMX Property Id',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    PoolFeatures: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Pool Features',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    PoolPrivateYN: {
      acceptCustomValue: false,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'YNType',
      fieldDisplayName: 'Pool Private YN',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['PoolFeatures'],
    },
    PoolType: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Pool Type',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['PoolFeatures', 'PoolPrivateYN'],
    },
    PorchType: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Porch Type',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['PatioAndPorchFeatures'],
    },
    PostalCode: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Postal Code',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    pricePerSquareFeet: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Price/sqft',
      isMultiSelect: false,
      maxSelection: null,
      prefix: '$',
      suffix: '/sqft',
      maxFractionDigits: 2,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    priorSaleDate: {
      acceptCustomValue: true,
      datatype: 'date',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Prior Sale Date',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    priorSalePrice: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Prior Sale Price',
      isMultiSelect: false,
      maxSelection: null,
      prefix: '$',
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    PropertyAttachedYN: {
      acceptCustomValue: false,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'YNType',
      fieldDisplayName: 'Property Attached YN',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    propertyCity: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'City',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    propertyImageURLs: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Property Image URLs',
      isMultiSelect: true,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    propertyLatitude: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Latitude',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 6,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    propertyLongitude: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Longitude',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 6,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    propertyRights: {
      acceptCustomValue: false,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'propertyRights',
      fieldDisplayName: 'Property Rights',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    propertyState: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'State',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    propertyStreetAddress: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Street Address',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    PropertySubType: {
      acceptCustomValue: false,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'propertySubType',
      fieldDisplayName: 'Property Type',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    PropertyType: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Property Type',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    propertyZip: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Zip',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    PublicRemarks: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Public Remarks',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    RentalIncome: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Rental Income',
      isMultiSelect: false,
      maxSelection: null,
      prefix: '$',
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    RentalSource: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'rentalSource',
      fieldDisplayName: 'Data Source',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    RentalSourceUrl: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Rental Source URL',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    RentalType: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'rentalType',
      fieldDisplayName: 'Rental Type',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    RevenueLast12Months: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Revenue Last 12 Months',
      isMultiSelect: false,
      maxSelection: null,
      prefix: '$',
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    Roof: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Roof',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    RoomsTotal: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Rooms Total',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    RVParkingDimensions: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'RV Parking Dimensions',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    SeniorCommunityYN: {
      acceptCustomValue: false,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'YNType',
      fieldDisplayName: 'Senior Community YN',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    SimilarityScore: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Similarity Score',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 2,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    Source: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'rentalSource',
      fieldDisplayName: 'Source',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    SourcePropertyId: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Source Property Id',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    SpecialListingConditions: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'listingCondition',
      fieldDisplayName: 'Special Listing Conditions',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    StandardStatus: {
      acceptCustomValue: false,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'mlsStatus',
      fieldDisplayName: 'Status',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    StateOrProvince: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'state',
      fieldDisplayName: 'State',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    StatusChangeTimestamp: {
      acceptCustomValue: true,
      datatype: 'date',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Status Change Timestamp',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    Stories: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Stories',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['StoriesTotal'],
    },
    StoriesTotal: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Total Stories',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['Stories'],
    },
    StreetAdditionalInfo: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Street Additional Info',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    StreetDirPrefix: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Street Dir Prefix',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    StreetDirSuffix: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Street Dir Suffix',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    StreetName: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Street Name',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    StreetNumber: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Street Number',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    StreetNumberNumeric: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Street Number Numeric',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    StreetSuffix: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Street Suffix',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    StreetSuffixModifier: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Street Suffix Modifier',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    SubdivisionName: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Subdivision Name',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    subjectCity: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'City',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    subjectState: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'State',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    subjectStreetAddress: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Street Address',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    subjectZip: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Zip',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    TaxAnnualAmount: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Tax Annual Amount',
      isMultiSelect: false,
      maxSelection: null,
      prefix: '$',
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    TaxLegalDescription: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Tax Legal Description',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    TaxYear: {
      acceptCustomValue: true,
      datatype: 'numberString',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Tax Year',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    UnitNumber: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Unit Number',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    UnparsedAddress: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Unparsed Address',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    View: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'viewFactor',
      fieldDisplayName: 'View',
      isMultiSelect: true,
      maxSelection: 2,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['View'],
    },
    ViewFactors: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'viewFactor',
      fieldDisplayName: 'View Factors',
      isMultiSelect: true,
      maxSelection: 2,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: ['View'],
    },
    ViewRating: {
      acceptCustomValue: false,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'viewRating',
      fieldDisplayName: 'View Rating',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    WaterFrontFeatures: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Water Front Features',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    WaterSource: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Water Source',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    WaterYN: {
      acceptCustomValue: false,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'YNType',
      fieldDisplayName: 'Water YN',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    weightage: {
      acceptCustomValue: true,
      datatype: 'number',
      decimalPrecision: 2,
      enumKey: null,
      fieldDisplayName: 'Weightage',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 2,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    YearBuilt: {
      acceptCustomValue: true,
      datatype: 'numberString',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Year Built',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    YearBuiltEffective: {
      acceptCustomValue: true,
      datatype: 'numberString',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Year Built Effective',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: 0,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    Zoning: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Zoning',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    ZoningCompliance: {
      acceptCustomValue: false,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'zoningCompliance',
      fieldDisplayName: 'Zoning Compliance',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    ZoningDescription: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: 'zoningDescription',
      fieldDisplayName: 'Zoning Description',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
    StreetAddress: {
      acceptCustomValue: true,
      datatype: 'string',
      decimalPrecision: null,
      enumKey: null,
      fieldDisplayName: 'Street Address',
      isMultiSelect: false,
      maxSelection: null,
      prefix: null,
      suffix: null,
      maxFractionDigits: null,
      abbreviationPrefix: null,
      abbreviationSuffix: null,
      ignoreAutoAdjustment: false,
      referenceFieldList: [],
    },
  };
}
